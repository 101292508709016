body {
  font-family: 'Trebuchet MS', sans-serif;
  background-color: #111111;
  color: #DDDDDD;
}

.name-header {
  font-size: 40px;
  text-align: center;
  margin: 30px;
  margin-bottom: 10;
}

.App {
  margin-left: 30px;
  width: 90%;
  position: absolute;
}